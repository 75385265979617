<!-- eslint-disable vue/no-v-html -->
<i18n>
{
    "en-BE": {
        "duplicates": "Duplicates",
        "duplicates_modal": {
            "title": "Showing duplicates for ",
            "close": "Close",
            "reason": "Reason",
            "reasons": {
                "same address": "Same address",
                "same customer_ref": "Same customer reference",
                "close point": "Close point"
            },
            "open_dvm": "Open Valuation Tool for this request",
            "copy_ref": "Copy request's reference"
        },
        "price_per_sqm": "Price per sqm",
        "address": "Address",
        "report": "Report",
        "anchors": {
            "flood_map": "Flood map",
            "description": "Description",
            "avm": "AVM",
            "dvm": "Desktop Valuation"
        },
        "actions": {
            "resubmit": "Resubmit",
            "onsite": "Request on site valuation",
            "incoherent_features": "Select incoherent features",
            "submit": "Submit valuation",
            "save": "Save valuation without submitting"
        },
        "incoherent_features_modal": {
            "title": "Incoherent features",
            "confirmation_message": "Valuation request sent for review!",
            "submit": "Send incoherent features",
            "cancel": "Cancel",
            "back_to_dashboard": "Go back to the Dashboard"
        },
        "onsite_modal": {
            "title": "Request on-site valuation",
            "reasons": {
                "onsite_streetview": "Streetview data not available or outdated",
                "onsite_incoherent_features": "Wrong input filled",
                "onsite_exceptional_property": "Exceptional property for the area",
                "onsite_commercial_building": "Commercial building",
                "onsite_volume_missing": "Building volume missing",
                "onsite_flood_zone": "Flood zone risk",
                "onsite_few_comparison": "Not enough comparison data points",
                "onsite_extensive_renovation": "Ratio renovation/current value",
                "onsite_others": "Other"
            },
            "confirmation_message": "Valuation request deferred on-site!",
            "submit": "Defer to on-site",
            "cancel": "Cancel",
            "back_to_dashboard": "Go back to the Dashboard"
        },
        "error_modal": {
            "title": "An error occured",
            "content": "An error occured while doing the <pre>{action}</pre> for the request <pre>{ref}</pre>\nPlease report it to the engineering team including those informations as well as this error message:",
            "actions": {
                "submitVal_put": "PUT call of the valuation submission",
                "submitVal_patch": "PATCH call of the valuation submission",
                "incoherent_put": "PUT call of the incoherent features submission",
                "incoherent_patch": "PATCH call of the incoherent features submission",
                "onsite_put": "PUT call of the onsite submission",
                "onsite_patch": "PATCH call of the onsite submission",
                "resubmit_put": "PUT call of the resubmission",
                "resubmit_patch": "PATCH call of the resubmission"
            },
            "cancel": "Cancel",
            "back_to_dashboard": "Go back to the Dashboard"
        },
        "success_modal": {
            "title": "Valuation submitted",
            "content": "Your valuation was submitted.",
            "actions": {
                "show_report": "Go to report",
                "stay_on_page": "Stay on this page",
                "back_to_dashboard": "Go back to the Dashboard"
            }
        }
    },
    "en-FR": {
        "duplicates": "Duplicates",
        "duplicates_modal": {
            "title": "Showing duplicates for ",
            "close": "Close",
            "reason": "Reason",
            "reasons": {
                "same address": "Same address",
                "same customer_ref": "Same customer reference",
                "close point": "Close point"
            },
            "open_dvm": "Open Valuation Tool for this request",
            "copy_ref": "Copy request's reference"
        },
        "price_per_sqm": "Price per sqm",
        "address": "Address",
        "report": "Report",
        "anchors": {
            "flood_map": "Flood map",
            "description": "Description",
            "avm": "AVM",
            "dvm": "Desktop Valuation"
        },
        "actions": {
            "resubmit": "Resubmit",
            "onsite": "Request on site valuation",
            "incoherent_features": "Select incoherent features",
            "submit": "Submit valuation",
            "save": "Save valuation without submitting"
        },
        "incoherent_features_modal": {
            "title": "Incoherent features",
            "confirmation_message": "Valuation request sent for review!",
            "submit": "Send incoherent features",
            "cancel": "Cancel",
            "back_to_dashboard": "Go back to the Dashboard"
        },
        "onsite_modal": {
            "title": "Request on-site valuation",
            "reasons": {
                "onsite_streetview": "Streetview data not available or outdated",
                "onsite_incoherent_features": "Wrong input filled",
                "onsite_exceptional_property": "Exceptional property for the area",
                "onsite_commercial_building": "Commercial building",
                "onsite_volume_missing": "Building volume missing",
                "onsite_flood_zone": "Flood zone risk",
                "onsite_few_comparison": "Not enough comparison data points",
                "onsite_extensive_renovation": "Ratio renovation/current value",
                "onsite_others": "Other"
            },
            "confirmation_message": "Valuation request deferred on-site!",
            "submit": "Defer to on-site",
            "cancel": "Cancel",
            "back_to_dashboard": "Go back to the Dashboard"
        },
        "error_modal": {
            "title": "An error occured",
            "content": "An error occured while doing the <pre>{action}</pre> for the request <pre>{ref}</pre>\nPlease report it to the engineering team including those informations as well as this error message:",
            "actions": {
                "submitVal_put": "PUT call of the valuation submission",
                "submitVal_patch": "PATCH call of the valuation submission",
                "incoherent_put": "PUT call of the incoherent features submission",
                "incoherent_patch": "PATCH call of the incoherent features submission",
                "onsite_put": "PUT call of the onsite submission",
                "onsite_patch": "PATCH call of the onsite submission",
                "resubmit_put": "PUT call of the resubmission",
                "resubmit_patch": "PATCH call of the resubmission"
            },
            "cancel": "Cancel",
            "back_to_dashboard": "Go back to the Dashboard"
        },
        "success_modal": {
            "title": "Valuation submitted",
            "content": "Your valuation was submitted.",
            "actions": {
                "show_report": "Go to report",
                "stay_on_page": "Stay on this page",
                "back_to_dashboard": "Go back to the Dashboard"
            }
        }
    },
    "en-NL": {
        "duplicates": "Duplicates",
        "duplicates_modal": {
            "title": "Showing duplicates for ",
            "close": "Close",
            "reason": "Reason",
            "reasons": {
                "same address": "Same address",
                "same customer_ref": "Same customer reference",
                "close point": "Close point"
            },
            "open_dvm": "Open Valuation Tool for this request",
            "copy_ref": "Copy request's reference"
        },
        "price_per_sqm": "Price per sqm",
        "address": "Address",
        "report": "Report",
        "anchors": {
            "flood_map": "Flood map",
            "description": "Description",
            "avm": "AVM",
            "dvm": "Desktop Valuation"
        },
        "actions": {
            "resubmit": "Resubmit",
            "onsite": "Request on site valuation",
            "incoherent_features": "Select incoherent features",
            "submit": "Submit valuation",
            "save": "Save valuation without submitting"
        },
        "incoherent_features_modal": {
            "title": "Incoherent features",
            "confirmation_message": "Valuation request sent for review!",
            "submit": "Send incoherent features",
            "cancel": "Cancel",
            "back_to_dashboard": "Go back to the Dashboard"
        },
        "onsite_modal": {
            "title": "Request on-site valuation",
            "reasons": {
                "onsite_streetview": "Streetview data not available or outdated",
                "onsite_incoherent_features": "Wrong input filled",
                "onsite_exceptional_property": "Exceptional property for the area",
                "onsite_commercial_building": "Commercial building",
                "onsite_volume_missing": "Building volume missing",
                "onsite_flood_zone": "Flood zone risk",
                "onsite_few_comparison": "Not enough comparison data points",
                "onsite_extensive_renovation": "Ratio renovation/current value",
                "onsite_others": "Other"
            },
            "confirmation_message": "Valuation request deferred on-site!",
            "submit": "Defer to on-site",
            "cancel": "Cancel",
            "back_to_dashboard": "Go back to the Dashboard"
        },
        "error_modal": {
            "title": "An error occured",
            "content": "An error occured while doing the <pre>{action}</pre> for the request <pre>{ref}</pre>\nPlease report it to the engineering team including those informations as well as this error message:",
            "actions": {
                "submitVal_put": "PUT call of the valuation submission",
                "submitVal_patch": "PATCH call of the valuation submission",
                "incoherent_put": "PUT call of the incoherent features submission",
                "incoherent_patch": "PATCH call of the incoherent features submission",
                "onsite_put": "PUT call of the onsite submission",
                "onsite_patch": "PATCH call of the onsite submission",
                "resubmit_put": "PUT call of the resubmission",
                "resubmit_patch": "PATCH call of the resubmission"
            },
            "cancel": "Cancel",
            "back_to_dashboard": "Go back to the Dashboard"
        },
        "success_modal": {
            "title": "Valuation submitted",
            "content": "Your valuation was submitted.",
            "actions": {
                "show_report": "Go to report",
                "stay_on_page": "Stay on this page",
                "back_to_dashboard": "Go back to the Dashboard"
            }
        }
    }
}
</i18n>

<template>
    <div id="valuation-tool">
        <b-loading
            v-if="isLoading"
            v-model="isLoading"
            :is-full-page="true"
            :can-cancel="false"
        />
        <div class="columns is-centered mt-1">
            <div class="column pb-0 pr-3 is-2 is-narrow sticky-column">
                <div class="card p-2 mb-2 mt-2">
                    <div>
                        <h5>{{ $t('address') }}:</h5>
                        <span>
                            {{ getAddress.full_address }}
                        </span>
                    </div>
                    <div><a @click="copyAddress">Copy address</a></div>
                    <div
                        v-if="
                            request.other_data.similar_requests &&
                                request.other_data.similar_requests.length
                        "
                    >
                        <a @click="load_duplicates()">
                            Duplicates
                            <b-icon type="is-danger" icon="alert-circle" />
                        </a>
                    </div>
                </div>
                <div class="card p-2 mb-2">
                    <div>Level: {{ request.features.level }}</div>
                    <div>
                        Request language:
                        {{ getFullStore.request_language.split('-')[0].toUpperCase() }}
                    </div>
                </div>
                <div class="card p-2 mb-2">
                    <template v-if="is_building">
                        <div>
                            {{ $t('features.f_epc') }}: {{ +request.features.f_epc }}
                        </div>
                        <div>
                            {{ $t('features.f_construction_year') }}:
                            {{ request.features.f_construction_year }}
                        </div>
                        <div>
                            {{ $t('features.f_living_area') }}:
                            {{ request.features.f_living_area }} m&sup2;
                        </div>
                    </template>
                    <template v-if="is_house">
                        <div>
                            {{ $t('features.f_parcel_area') }}:
                            {{ Math.round(request.features.f_parcel_area * 100) / 100 }}
                            m&sup2;
                        </div>
                        <div>
                            {{ $t('features.f_garden_area') }}:
                            {{ Math.round(request.features.f_garden_area * 100) / 100 }}
                            m&sup2;
                        </div>
                    </template>
                    <div v-if="!is_new">
                        {{ $t('price_per_sqm') }}: {{ withSpaces(pricePerSqm) }} €
                    </div>
                </div>

                <div v-show="request.features.f_flood_risk" class="card mb-2 p-2">
                    <h5
                        v-if="request.features.f_flood_risk"
                        :class="
                            request.features.f_flood_risk.toLowerCase() !== 'none' &&
                                'has-text-danger has-text-weight-bold'
                        "
                    >
                        {{ $t('features.f_flood_risk') }}:
                        {{ $t(`value.${request.features.f_flood_risk.toLowerCase()}`) }}
                        <b-icon
                            v-if="request.features.f_flood_risk.toLowerCase() !== 'none'"
                            type="is-danger"
                            icon="alert-circle"
                        />
                    </h5>
                </div>

                <div class="card mb-2 p-2">
                    <div>
                        <router-link
                            :to="{
                                name: 'request',
                                params: {
                                    action: 'extra-info',
                                    lang: $route.params.lang,
                                },
                                query: {
                                    valuation_request_ref,
                                },
                            }"
                            target="_blank"
                        >
                            {{ valuation_request_ref }}
                        </router-link>
                    </div>
                    <div>
                        <router-link
                            :to="{
                                name: 'report',
                                query: {
                                    valuation_request_ref,
                                },
                            }"
                            target="_blank"
                        >
                            {{ $t('report') }}
                        </router-link>
                    </div>
                    <div>
                        <a
                            :href="
                                `https://maps.google.com/?q=${request.features.f_lat},${request.features.f_lng}&ll=${request.features.f_lat},${request.features.f_lng}&z=19`
                            "
                            target="_blank"
                        >
                            Google Maps
                        </a>
                    </div>
                    <div v-if="regionalMap">
                        <a :href="regionalMap" target="_blank">
                            Regional map
                        </a>
                    </div>
                </div>

                <div
                    class="button-group is-flex is-flex-direction-column is-align-items-stretch"
                >
                    <b-button class="mb-2" type="is-info" @click="openChat">
                        {{ isChatAllowed ? 'Chat & ' : '' }}
                        History
                        <b-tag
                            v-if="hasUnreadMessages && isChatAllowed"
                            rounded
                            type="is-warning"
                        >
                            NEW
                        </b-tag>
                    </b-button>
                </div>

                <div class="card links mb-2 p-2">
                    <a @click="jumpTo('#flood-map')">{{ $t('anchors.flood_map') }}</a>
                    <a @click="jumpTo('#description')">{{ $t('anchors.description') }}</a>
                    <a v-if="is_building" @click="jumpTo('#avm')">
                        {{ $t('anchors.avm') }}
                    </a>
                    <a @click="jumpTo('#dvm')">{{ $t('anchors.dvm') }}</a>
                </div>

                <b-field :label="$t('dvm_features.internal_remarks')" class="mx-0 mb-0">
                    <b-input v-model="internal_remarks" type="textarea" rows="8" />
                </b-field>

                <div class="mb-5"><a @click="copyRemarks">Copy remarks</a></div>

                <div
                    class="button-group is-flex is-flex-direction-column is-align-items-stretch"
                >
                    <b-button class="mb-2" type="is-info" @click="resubmit">
                        {{ $t('actions.resubmit') }}
                    </b-button>
                    <b-button
                        class="mb-2"
                        type="is-warning"
                        @click="showIncoherentFeatures"
                    >
                        {{ $t('actions.incoherent_features') }}
                    </b-button>
                    <b-button class="mb-2" type="is-danger" @click="requestOnSite">
                        {{ $t('actions.onsite') }}
                    </b-button>
                </div>
            </div>

            <div class="column pb-0 pr-0 is-8 pb-5">
                <div id="flood-map" class="map-container mt-2">
                    <re-map
                        :building-id="request.features.building_id"
                        :parcel-ids="request.features.parcel_ids"
                        :level="request.features.level"
                        :region="request.features.region"
                        :center="{
                            lat: request.features.f_lat,
                            lng: request.features.f_lng,
                        }"
                        :map-scene2d="map_scene2d"
                        :read-only="true"
                        :focus-layer="'building'"
                        :layers-control-visible="false"
                        :options="{
                            zoomControl: true,
                            attributionControl: false,
                            scrollWheelZoom: false,
                            attributionControl: false,
                            scrollWheelZoom: false,
                            doubleClickZoom: true,
                            boxZoom: true,
                            dragging: true,
                        }"
                        :aerial-tile-visible="false"
                        :cadastral-tile-visible="false"
                        class="has-ratio"
                        @update:mapScene2d="SET_MAP_SCENE2D($event)"
                    ></re-map>
                </div>
                <div id="description" class="pt-5">
                    <description />
                </div>
                <div v-if="getDocuments" id="documents" class="pt-5 mt-2 mb-4">
                    <documents-mgmt />
                </div>
                <div id="avm" class="pt-5">
                    <avm
                        :avm-rental="request.avm_rental.body"
                        :avm-transaction="request.avm_transaction.body"
                        :features="request.features"
                        :address="address"
                        :reconstruction-value="reconstruction_value"
                        :forced-sale-value="forced_sale_value"
                        :update-remarks="setInternalRemarks"
                    />
                </div>
                <div id="dvm" class="pt-5 is-full-width">
                    <desktop-value
                        v-if="is_new || is_plot || reconstruction_value_loaded"
                        :features="request.features"
                        :prices="dvmPrices"
                        :reconstruction-value="reconstruction_value"
                        :update-remarks="setInternalRemarks"
                    />
                    <b-loading v-else />
                </div>
                <div
                    class="is-flex is-flex-direction-row is-justify-content-center gap-1"
                >
                    <b-button
                        type="is-primary is-flex-grow-2"
                        @click="submitValuation(false)"
                    >
                        {{ $t('actions.save') }}
                    </b-button>
                    <b-button
                        type="is-success is-flex-grow-2"
                        @click="submitValuation(true)"
                    >
                        {{ $t('actions.submit') }}
                    </b-button>
                </div>
            </div>
        </div>

        <b-modal v-if="isChatOpen" v-model="isChatOpen" width="640px">
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Conversation with the bank agent</p>
                    <button type="button" class="delete" @click="closeChat" />
                </header>
                <section class="modal-card-body">
                    <chat />
                </section>
                <footer class="modal-card-foot">
                    <div />
                </footer>
            </div>
        </b-modal>

        <b-modal
            v-if="isIncoherentModalShown"
            v-model="isIncoherentModalShown"
            :on-cancel="closeIncoherentModal"
            width="640px"
        >
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        {{ $t('incoherent_features_modal.title') }}
                    </p>
                    <button type="button" class="delete" @click="closeIncoherentModal" />
                </header>
                <section class="modal-card-body">
                    <div v-if="!incoherentFeaturesSent" class="incoherent-features-grid">
                        <template
                            v-for="(feature, i) in incoherent_list.filter(
                                (f) => f.visible
                            )"
                        >
                            <b-field
                                v-if="feature.hasField"
                                :key="`nc_cp_inco_${i}_field`"
                                :label="
                                    feature.label || $t(`features.${feature.fieldName}`)
                                "
                                custom-class="mb-0 pt-0"
                                class="mb-0 is-align-items-center"
                                :type="
                                    incoherentFeatures[feature.fieldName]
                                        ? 'is-danger'
                                        : ''
                                "
                            >
                                <b-input
                                    disabled
                                    :value="request.features[feature.fieldName]"
                                />
                            </b-field>
                            <div v-else :key="`nc_cp_inco_${i}_field`" class="mt-4">
                                <span class="incoherent-no-field">
                                    {{
                                        feature.label ||
                                            $t(`features.${feature.fieldName}`)
                                    }}
                                </span>
                            </div>
                            <b-switch
                                :key="`nc_cp_inco_${i}_switch`"
                                v-model="incoherentFeatures[feature.fieldName]"
                                type="is-danger"
                                class="mb-2 mr-0"
                            />
                        </template>
                    </div>
                    <div v-else>
                        {{ $t('incoherent_features_modal.confirmation_message') }}
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <b-field v-if="!incoherentFeaturesSent" label="Optional comments">
                        <b-input v-model="rejection_comments" type="textarea" :rows="3" />
                    </b-field>
                    <div>
                        <b-button
                            v-if="!incoherentFeaturesSent"
                            :label="$t('incoherent_features_modal.submit')"
                            type="is-danger"
                            :disabled="!isIncoherentSubmitEnabled"
                            @click="submitIncoherentFeatures"
                        />
                        <b-button
                            v-if="!incoherentFeaturesSent"
                            :label="$t('incoherent_features_modal.cancel')"
                            @click="closeIncoherentModal"
                        />
                        <b-button
                            v-else
                            :label="$t('incoherent_features_modal.back_to_dashboard')"
                            @click="backToDashboard"
                        />
                    </div>
                </footer>
            </div>
        </b-modal>

        <b-modal
            v-if="isOnSiteModalShown"
            v-model="isOnSiteModalShown"
            :on-cancel="cancelOnSite"
            width="640px"
        >
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        {{ $t('onsite_modal.title') }}
                    </p>
                    <button type="button" class="delete" @click="cancelOnSite" />
                </header>
                <section class="modal-card-body">
                    <div v-if="!onSiteSent">
                        <b-field label="Select on-site reason">
                            <div class="is-flex is-flex-direction-column">
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_streetview"
                                    class="mb-2"
                                >
                                    {{ $t('onsite_modal.reasons.onsite_streetview') }}
                                </b-radio>
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_incoherent_features"
                                    class="mb-2"
                                >
                                    {{
                                        $t(
                                            'onsite_modal.reasons.onsite_incoherent_features'
                                        )
                                    }}
                                </b-radio>
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_exceptional_property"
                                    class="mb-2"
                                >
                                    {{
                                        $t(
                                            'onsite_modal.reasons.onsite_exceptional_property'
                                        )
                                    }}
                                </b-radio>
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_commercial_building"
                                    class="mb-2"
                                >
                                    {{
                                        $t(
                                            'onsite_modal.reasons.onsite_commercial_building'
                                        )
                                    }}
                                </b-radio>
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_volume_missing"
                                    class="mb-2"
                                >
                                    {{ $t('onsite_modal.reasons.onsite_volume_missing') }}
                                </b-radio>
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_flood_zone"
                                    class="mb-2"
                                >
                                    {{ $t('onsite_modal.reasons.onsite_flood_zone') }}
                                </b-radio>
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_few_comparison"
                                    class="mb-2"
                                >
                                    {{ $t('onsite_modal.reasons.onsite_few_comparison') }}
                                </b-radio>
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_extensive_renovation"
                                    class="mb-2"
                                >
                                    {{
                                        $t(
                                            'onsite_modal.reasons.onsite_extensive_renovation'
                                        )
                                    }}
                                </b-radio>
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_others"
                                    class="mb-2"
                                >
                                    {{ $t('onsite_modal.reasons.onsite_others') }}
                                </b-radio>
                            </div>
                        </b-field>
                    </div>
                    <div v-else>
                        {{ $t('onsite_modal.confirmation_message') }}
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <b-field v-if="!onSiteSent" label="Optional comments">
                        <b-input v-model="rejection_comments" type="textarea" :rows="3" />
                    </b-field>
                    <div>
                        <b-button
                            v-if="!onSiteSent"
                            :label="$t('onsite_modal.submit')"
                            type="is-danger"
                            :disabled="!onsite_reasons"
                            @click="confirmOnSite"
                        />
                        <b-button
                            v-if="!onSiteSent"
                            :label="$t('onsite_modal.cancel')"
                            @click="cancelOnSite"
                        />
                        <b-button
                            v-else
                            :label="$t('onsite_modal.back_to_dashboard')"
                            @click="backToDashboard"
                        />
                    </div>
                </footer>
            </div>
        </b-modal>

        <b-modal
            v-if="isSuccessModalShown"
            v-model="isSuccessModalShown"
            width="640px"
            :on-cancel="closeSuccessModal"
        >
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        {{ $t('success_modal.title') }}
                    </p>
                    <button type="button" class="delete" @click="closeSuccessModal" />
                </header>
                <section class="modal-card-body">
                    <div>
                        <p>
                            {{ $t('success_modal.content') }}
                        </p>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <div>
                        <b-button
                            :label="$t('success_modal.actions.show_report')"
                            @click="goToReport"
                        />
                        <b-button
                            :label="$t('success_modal.actions.stay_on_page')"
                            @click="closeSuccessModal"
                        />
                        <b-button
                            :label="$t('success_modal.actions.back_to_dashboard')"
                            @click="backToDashboard"
                        />
                    </div>
                </footer>
            </div>
        </b-modal>

        <b-modal
            v-if="isErrorModalShown"
            v-model="isErrorModalShown"
            width="640px"
            :on-cancel="closeErrorModal"
        >
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        {{ $t('error_modal.title') }}
                    </p>
                    <button type="button" class="delete" @click="closeErrorModal" />
                </header>
                <section class="modal-card-body">
                    <div>
                        <p
                            v-html="
                                $t('error_modal.content', {
                                    action: $t(`error_modal.actions.${errorAction}`),
                                    ref: valuation_request_ref,
                                })
                            "
                        />
                    </div>
                    <div>
                        <pre>{{ errorMessage }}</pre>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <div>
                        <b-button
                            :label="$t('error_modal.cancel')"
                            @click="closeErrorModal"
                        />
                        <b-button
                            :label="$t('error_modal.back_to_dashboard')"
                            @click="backToDashboard"
                        />
                    </div>
                </footer>
            </div>
        </b-modal>

        <b-modal
            v-model="shownDuplicate"
            trap-focus
            :can-cancel="['escape']"
            width="fit-content"
        >
            <div v-if="shownDuplicate" class="card p-4">
                <p class="mb-5 has-text-centered is-size-5 has-text-black">
                    <b>
                        {{ $t('duplicates_modal.title') }}
                        {{ shownDuplicate.valuation_request_ref }}
                    </b>
                </p>
                <b-table
                    :data="duplicatesData"
                    hoverable
                    mobile-cards
                    paginated
                    scrollable
                    pagination-size="is-small"
                    default-sort="modified_at"
                    default-sort-direction="desc"
                    :loading="isDuplicatesLoading"
                    :per-page="10"
                    :current-page.sync="currentDuplicatesPage"
                    :searchable="false"
                    class="duplicates-table"
                >
                    <template v-for="column in duplicates_columns">
                        <b-table-column
                            :key="column.valuation_request_ref"
                            v-bind="column"
                        >
                            <template #header>
                                <div
                                    class="is-flex-direction-column is-align-items-center is-justify-content-flex-end"
                                >
                                    <div class="nowrap">{{ column.label }}</div>
                                    <div class="has-text-primary mt-3">
                                        {{
                                            column.date
                                                ? date_format(
                                                      shownDuplicate[column.field]
                                                  )
                                                : shownDuplicate[column.field]
                                        }}
                                    </div>
                                </div>
                            </template>
                            <template v-if="column.date" #default="props">
                                <span>
                                    {{ date_format(props.row[column.field]) }}
                                </span>
                            </template>
                            <template
                                v-else-if="column.field === 'reason'"
                                #default="props"
                            >
                                {{
                                    $t(
                                        `duplicates_modal.reasons.${
                                            props.row[column.field]
                                        }`
                                    )
                                }}
                            </template>
                            <template
                                v-else-if="column.field === 'valuation_request_ref'"
                                #default="props"
                            >
                                <router-link
                                    v-if="
                                        props.row.status === 'submitted' ||
                                            props.row.status === 'needs-review' ||
                                            props.row.status === 'valued' ||
                                            props.row.status === 'exotic'
                                    "
                                    :to="{
                                        name: 'dvm',
                                        params: {
                                            lang: $route.params.lang,
                                        },
                                        query: {
                                            valuation_request_ref:
                                                props.row.valuation_request_ref,
                                        },
                                    }"
                                    target="_blank"
                                >
                                    {{ props.row[column.field] }}
                                </router-link>
                                <span v-else>
                                    {{ props.row[column.field] }}
                                </span>
                            </template>
                            <template
                                v-else-if="column.field === 'display_request_ref'"
                                #default="props"
                            >
                                <a
                                    :href="
                                        `//${
                                            props.row.deployment
                                        }.rock.estate/valuation/${
                                            $route.params.lang
                                        }/request/extra-info?valuation_request_ref=${
                                            props.row[column.field]
                                        }`
                                    "
                                    target="_blank"
                                >
                                    {{ props.row[column.field] }}
                                </a>
                            </template>
                            <template
                                v-else-if="
                                    ['last_submission_lang', 'displayOwner'].includes(
                                        column.field
                                    )
                                "
                                #default="props"
                            >
                                <div class="level top">
                                    <span class="level-left">
                                        {{ props.row[column.field] }}
                                    </span>
                                </div>
                            </template>
                            <template v-else #default="props">
                                {{ props.row[column.field] }}
                            </template>
                        </b-table-column>
                    </template>
                </b-table>
                <div
                    class="buttons is-flex-direction-row is-justify-content-end is-align-items-center mt-5"
                >
                    <b-button @click="close_duplicates">
                        {{ $t('duplicates_modal.close') }}
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import axios from 'axios'
import Chat from '@/components/common/Chat.vue'
import Description from './dvm-components/Description.vue'
import DocumentsMgmt from './dvm-components/DocsMgmt.vue'
import Avm from './dvm-components/AVM.vue'
import DesktopValue from './dvm-components/DesktopValue.vue'
import ReMap from '@/components/common/Map.vue'
import utils from '@/shared/plugins/utils'

const date_formatter = new Intl.DateTimeFormat('fr-BE', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    /*second: 'numeric',*/
    timeZone: 'Europe/Brussels',
})

export default {
    name: 'DVM',
    components: {
        Description,
        Avm,
        DesktopValue,
        ReMap,
        DocumentsMgmt,
        Chat,
    },
    data() {
        return {
            isLoading: true,

            isChatOpen: false,

            shownDuplicate: null,
            isDuplicatesLoading: false,
            duplicatesData: [],
            currentDuplicatesPage: 1,

            scrollPosition: 0,
            shake: false,

            request: {},
            valuation_request_ref: null,
            address: {},

            map_scene2d: {
                parcel_geojson: null,
                building_geojson: null,
                address_geojson: null,
                flood_geojson: null,
            },

            reconstruction_value: 0,
            internal_remarks: '',
            valuer_remarks: '',
            rejection_comments: '',

            reconstruction_value_loaded: false,

            isSuccessModalShown: false,

            isErrorModalShown: false,
            errorMessage: '',
            errorAction: null,

            isOnSiteModalShown: false,
            onsite_reasons: null,
            onSiteSent: false,

            isIncoherentModalShown: false,
            incoherentFeaturesSent: false,
            incoherentFeatures: {
                f_epc: false,
                f_construction_year: false,
                f_living_area: false,
                f_parcel_area: false,
                f_garden_area: false,
                f_building_area: false,
                f_bottom_floor: false,
                f_top_floor: false,
                f_floors: false,
                f_garden_common: false,
                f_garden_private: false,
                f_n_closed_garage: false,
                f_n_parking_spot: false,
                f_cellar_attic: false,
                renovation_cost: false,
                f_bedrooms: false,

                construction_cost: false,
                parcel_cost: false,
                exterior_image: false,
                garden_blueprint: false,
                vat: false,
                parcel_selection: false,

                view_parcel: false,

                other: false,
            },
        }
    },
    computed: {
        ...mapGetters(['getFullStore', 'getDocuments', 'hasUnreadMessages']),
        ...mapGetters('Address', [
            'getAddress',
            'getValuation',
            'getRejections',
            'getFeatures',
            'getPricesOverride',
            'getDVMFeatures',
            'getPriceOverrideStatus',
        ]),
        isChatAllowed() {
            return (
                typeof process.env.VUE_APP_ENABLE_CHAT !== 'undefined' &&
                process.env.VUE_APP_ENABLE_CHAT.toLowerCase() === 'true'
            )
        },
        is_apartment() {
            return utils.is_apartment(this.request.features.f_building_type)
        },
        is_house() {
            return utils.is_house(this.request.features.f_building_type)
        },
        is_building() {
            return utils.is_building(this.request.features.f_building_type)
        },
        is_new() {
            return utils.is_new(this.request.features.f_building_type)
        },
        is_plot() {
            return utils.is_plot(this.request.features.f_building_type)
        },
        regionalMap() {
            switch (this.getFeatures.region) {
                case 'BE-VLG':
                    return 'https://www.geopunt.be/'
                case 'BE-BRU':
                    return 'https://datastore.brussels/web/map'
                case 'BE-WAL':
                    return 'https://geoportail.wallonie.be/walonmap'
                default:
                    return null
            }
        },
        isDispatchDashboard() {
            return (
                typeof process.env.VUE_APP_DISPATCHED_DEPLOYMENTS !== 'undefined' &&
                process.env.VUE_APP_DISPATCHED_DEPLOYMENTS !== ''
            )
        },
        isIncoherentSubmitEnabled() {
            let returnBool = false

            Object.keys(this.incoherentFeatures).forEach((key) => {
                if (this.incoherentFeatures[key] == true) {
                    returnBool = true
                }
            })

            return returnBool
        },
        building_type() {
            return this.request.features?.f_building_type
        },
        pricePerSqm() {
            return Math.round(
                this.request.avm_transaction.body.estimation.asking_price_q50 /
                    (this.is_building
                        ? this.request.features.f_living_area
                        : this.request.features.f_parcel_area)
            )
        },
        forced_sale_value() {
            return utils.forced_sale_value(
                this.request.avm_transaction.body.estimation.asking_price_q50
            )
        },
        dvmPrices() {
            const prices = {}

            prices.avm_price =
                Math.round(
                    this.request.avm_transaction.body.estimation.asking_price_q50 / 1000
                ) * 1000

            prices.market_value =
                Math.round(
                    this.request.avm_transaction.body.estimation.asking_price_q50 / 1000
                ) * 1000

            prices.forced_sale_value = this.forced_sale_value

            prices.rental_value = this.is_plot
                ? 'NA'
                : Math.round(
                      this.request.avm_rental.body.estimation.asking_price_q50 / 10
                  ) * 10

            prices.reconstruction_value = this.reconstruction_value

            return prices
        },
        duplicates_columns() {
            return [
                {
                    field: 'deployment',
                    label: this.$t('customer'),
                    width: '30',
                    visible: this.isDispatchDashboard,
                },
                {
                    field: 'customer_ref',
                    label: 'Customer reference',
                    width: '40',
                    sortable: true,
                    centered: true,
                },
                {
                    field: 'valuation_request_ref',
                    label: 'Request reference',
                    visible: !this.isDispatchDashboard,
                    centered: true,
                },
                {
                    field: 'display_request_ref',
                    label: 'Request reference',
                    visible: this.isDispatchDashboard,
                    centered: true,
                },
                {
                    field: 'address',
                    label: this.$t('address'),
                    centered: true,
                },
                {
                    field: 'reason',
                    label: this.$t('duplicates_modal.reason'),
                    sortable: true,
                },
                {
                    field: 'displayStatus',
                    label: 'Status',
                    centered: true,
                    sortable: true,
                },
                {
                    field: 'created_at',
                    label: 'Created at',
                    centered: true,
                    sortable: true,
                    date: true,
                },
                {
                    field: 'displayOwner',
                    label: 'Owner',
                    visible: true,
                    centered: true,
                    sortable: true,
                },
                {
                    field: 'displayValuer',
                    label: 'Valuer',
                    visible: true,
                    centered: true,
                    sortable: true,
                },
            ]
        },
        incoherent_list() {
            return [
                {
                    fieldName: 'f_epc',
                    visible: !this.is_new && this.is_building,
                    hasField: true,
                },
                {
                    fieldName: 'f_construction_year',
                    visible: !this.is_new && this.is_building,
                    hasField: true,
                },
                {
                    fieldName: 'f_living_area',
                    visible: !this.is_new && this.is_building,
                    hasField: true,
                },
                {
                    fieldName: 'f_parcel_area',
                    visible: this.is_house,
                    hasField: true,
                },
                {
                    fieldName: 'f_garden_area',
                    visible: this.building_type === 'house',
                    hasField: true,
                },
                {
                    fieldName: 'f_building_area',
                    visible: this.building_type === 'house',
                    hasField: true,
                },
                {
                    fieldName: 'f_bottom_floor',
                    visible: this.building_type === 'apartment',
                    hasField: true,
                },
                {
                    fieldName: 'f_top_floor',
                    visible: this.building_type === 'apartment',
                    hasField: true,
                },
                {
                    fieldName: 'f_floors',
                    visible: this.building_type === 'apartment',
                    hasField: true,
                },
                {
                    fieldName: 'f_bedrooms',
                    visible: this.building_type === 'apartment',
                    hasField: true,
                },
                {
                    fieldName: 'f_garden_common',
                    visible: this.is_apartment,
                    hasField: true,
                },
                {
                    fieldName: 'f_garden_private',
                    visible: this.building_type === 'apartment',
                    hasField: true,
                },
                {
                    fieldName: 'f_n_closed_garage',
                    visible: this.is_apartment,
                    hasField: true,
                },
                {
                    fieldName: 'f_n_parking_spot',
                    visible: this.is_apartment,
                    hasField: true,
                },
                {
                    fieldName: 'f_cellar_attic',
                    visible: this.is_apartment,
                    hasField: true,
                },
                {
                    fieldName: 'renovation_cost',
                    visible:
                        !this.is_new &&
                        this.is_building &&
                        this.request.features.renovation_cost,
                    hasField: true,
                },
                {
                    fieldName: 'construction_cost',
                    visible: this.is_new,
                    hasField: true,
                },
                {
                    fieldName: 'parcel_cost',
                    visible: this.is_new || this.is_plot,
                    hasField: true,
                },
                {
                    fieldName: 'exterior_image',
                    label: 'No graphic image of the building',
                    visible: this.is_new,
                    hasField: false,
                },
                {
                    fieldName: 'garden_blueprint',
                    label: 'No technical drawing of the garden',
                    visible: this.building_type === 'new_house',
                    hasField: false,
                },
                {
                    fieldName: 'vat',
                    label: 'VAT',
                    visible: this.is_new,
                    hasField: false,
                },
                {
                    fieldName: 'parcel_selection',
                    label: 'Parcel selection',
                    visible: this.is_new,
                    hasField: false,
                },

                {
                    fieldName: 'view_parcel',
                    label: 'No image parcel',
                    visible: this.is_plot,
                    hasField: false,
                },
                {
                    fieldName: 'other',
                    label: 'Other',
                    visible: true,
                    hasField: false,
                },
            ]
        },
    },
    watch: {
        internal_remarks(val) {
            this.SET_VALUATION({ internal_remarks: val })
        },
    },
    mounted() {
        this.request = this.getFullStore.Address
        this.valuation_request_ref = this.getFullStore.valuation_request_ref
        this.address = this.request.address
        if (!(this.is_new || this.is_plot)) {
            this.getReconstructionValue()
        }
        this.map_scene2d = this.getFullStore.Address.map_scene2d
        if (this.getValuation.internal_remarks) {
            this.internal_remarks = this.getValuation.internal_remarks
        } else {
            this.RESET_INTERNAL_REMARKS()
        }
        if (this.getValuation.remarks) {
            this.valuer_remarks = this.getValuation.remarks
        } else {
            this.RESET_VALUER_REMARKS()
        }
        this.isLoading = false
    },
    methods: {
        ...mapMutations('Address', [
            'UPDATE_DVM_REJECTIONS',
            'SET_DVM_FEATURES',
            'SET_VALUATION',
            'SET_MAP_SCENE2D',
            'RESET_INTERNAL_REMARKS',
            'RESET_VALUER_REMARKS',
        ]),
        date_format: date_formatter.format,
        display_address(el) {
            let a = utils.full_address(el.address, el.features)
            if (utils.via_address(el.address, el.features) !== '') {
                a = `${a} (via ${utils.via_address(el.address, el.features)})`
            }
            return a
        },
        withSpaces(number) {
            return utils.numberWithSpaces(number)
        },
        shakeAnimation() {
            this.shake = true
        },
        // VueRouter messes up with anchor links as it considers it a new navigation,
        // it makes the whole thing very slow because it reloads the component.
        // This replaces the anchor behaviour to actually act like we want it to.
        jumpTo(anchor) {
            let target = anchor
            if (target.startsWith('#')) {
                target = anchor.slice(1)
            }

            document.getElementById(target).scrollIntoView(true)
        },
        copyAddress() {
            this.$copyText(this.getAddress.full_address)
                .then(() => {
                    this.onCopy()
                })
                .catch(() => {
                    this.onCopyError()
                })
        },
        onCopy() {
            this.$buefy.toast.open({
                duration: 5000,
                message: 'Copied to clipboard!',
                position: 'is-bottom',
                type: 'is-success',
            })
        },
        copyRemarks() {
            this.$copyText(this.internal_remarks)
                .then(() => {
                    this.onCopy()
                })
                .catch(() => {
                    this.onCopyError()
                })
        },
        onCopyError() {
            this.$buefy.toast.open({
                duration: 5000,
                message: 'Something went wrong, please get in touch with the tech team',
                position: 'is-bottom',
                type: 'is-danger',
            })
        },
        openChat() {
            this.isChatOpen = true
        },
        closeChat() {
            this.isChatOpen = false
        },
        getReconstructionValue() {
            if (this.is_plot) return 'N/A'
            let url = utils.urlJoin(
                process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '',
                ['estimate', 'reconstruction', this.request.features.building_id]
            )
            url += `?f_building_type=${this.request.features.f_building_type}&f_living_area=${this.request.features.f_living_area}`
            axios.get(url).then((res) => {
                this.reconstruction_value = res.data.reconstruction_value
                    ? Math.round(res.data.reconstruction_value / 1000) * 1000
                    : null
                this.reconstruction_value_loaded = true
            })
        },
        setInternalRemarks(remarks) {
            if (!utils.isEmptyStr(this.internal_remarks)) this.internal_remarks += '\n'

            this.internal_remarks += remarks

            this.SET_DVM_FEATURES({ internal_remarks: this.internal_remarks })
        },
        closeModals() {
            this.isIncoherentModalShown = false
            this.isOnSiteModalShown = false
            this.isSuccessModalShown = false
        },
        closeErrorModal() {
            this.errorMessage = ''
            this.errorAction = null
            this.isErrorModalShown = false
        },
        closeSuccessModal() {
            this.isSuccessModalShown = false
        },
        showError(e, action) {
            this.errorMessage = e.toString()
            this.errorAction = action
            this.isErrorModalShown = true
        },
        showSuccess() {
            this.isSuccessModalShown = true
        },
        cleanValuation(valuation) {
            let newVal = { ...this.getValuation }
            Object.assign(newVal, valuation)

            Object.keys(newVal.influence).forEach((key) => {
                let val = newVal.influence[key]
                newVal.influence[key] = +val
            })

            newVal.total_influence = +newVal.total_influence

            newVal.market_value = newVal.override_price
                ? +this.getPricesOverride.market_value
                : Math.round(
                      (+this.dvmPrices.market_value *
                          (1 + newVal.total_influence / 100)) /
                          1000
                  ) * 1000
            newVal.reconstruction_value = newVal.override_price
                ? +this.getPricesOverride.reconstruction_value
                : Math.round(
                      (+this.dvmPrices.reconstruction_value *
                          (1 + newVal.total_influence / 100)) /
                          1000
                  ) * 1000
            newVal.forced_sale_value = newVal.override_price
                ? +this.getPricesOverride.forced_sale_value
                : Math.round(
                      (+this.dvmPrices.forced_sale_value *
                          (1 + newVal.total_influence / 100)) /
                          1000
                  ) * 1000
            if (this.is_building) {
                newVal.rental_value = newVal.override_price
                    ? +this.getPricesOverride.rental_value
                    : Math.round(
                          (+this.dvmPrices.rental_value *
                              (1 + newVal.total_influence / 100)) /
                              1000
                      ) * 1000
            } else {
                newVal.rental_value = null
            }

            return newVal
        },
        async submitValuation(changeStatus) {
            const now = new Date()
            const valuationDate = `${now.getFullYear()}-${now.getMonth() +
                1}-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`

            const valuation = {
                date: valuationDate,
                type: 'dvm',
                valuer: this.$store.state.auth.email,
            }

            const cleanVal = this.cleanValuation(valuation)

            this.SET_VALUATION(cleanVal)

            let promises = []

            promises.push(
                this.$axios.patch(
                    utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                        'request',
                        this.valuation_request_ref,
                    ]),
                    { valuation: this.getValuation, dvm_features: this.getDVMFeatures }
                )
            )

            if (this.getDocuments) {
                promises.push(
                    this.$axios.patch(
                        utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                            'request',
                            this.valuation_request_ref,
                            'documents',
                        ]),
                        this.getDocuments
                    )
                )
            }

            if (!changeStatus) {
                await Promise.all(promises)
                    .then(() => {
                        this.backToDashboard()
                    })
                    .catch((e) => {
                        this.closeModals()
                        this.showError(e, 'submitVal_put')
                        console.error(e)
                    })

                return
            }

            await Promise.all(promises)
                .then(() => {
                    this.$axios
                        .put(
                            utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                                'request',
                                this.valuation_request_ref,
                                'status',
                            ]),
                            null,
                            { params: { action: 'submit-dvm-valuation' } }
                        )
                        .then(() => {
                            this.showSuccess()
                        })
                        .catch((e) => {
                            this.closeModals()
                            this.showError(e, 'submitVal_put')
                            console.error(e)
                        })
                })
                .catch((e) => {
                    this.closeModals()
                    this.showError(e, 'submitVal_patch')
                    console.error(e)
                })
        },
        showIncoherentFeatures() {
            this.isIncoherentModalShown = true
        },
        closeIncoherentModal() {
            this.isIncoherentModalShown = false
        },
        async submitIncoherentFeatures() {
            let incoherent_features = {}

            Object.keys(this.incoherentFeatures).forEach((key) => {
                if (this.incoherentFeatures[key]) {
                    incoherent_features[key] = this.request.features[key]
                }
            })

            const now = new Date()
            const rejectionDate = `${now.getFullYear()}-${now.getMonth() +
                1}-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`

            const rejection = {
                date: rejectionDate,
                valuer: this.$store.state.auth.email,
                incoherent_features,
                rejection_comments: this.rejection_comments,
            }

            this.UPDATE_DVM_REJECTIONS(rejection)

            await this.$axios
                .patch(
                    utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                        'request',
                        this.valuation_request_ref,
                    ]),
                    { dvm_rejections: rejection }
                )
                .then(() => {
                    this.$axios
                        .put(
                            utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                                'request',
                                this.valuation_request_ref,
                                'status',
                            ]),
                            null,
                            { params: { action: 'flag-for-review' } }
                        )
                        .then(() => {
                            this.incoherentFeaturesSent = true
                        })
                        .catch((e) => {
                            this.closeModals()
                            this.showError(e, 'incoherent_put')
                            console.error(e)
                        })
                })
                .catch((e) => {
                    this.closeModals()
                    this.showError(e, 'incoherent_patch')
                    console.error(e)
                })
        },
        requestOnSite() {
            this.isOnSiteModalShown = true
        },
        cancelOnSite() {
            this.isOnSiteModalShown = false
        },
        async confirmOnSite() {
            const now = new Date()
            const rejectionDate = `${now.getFullYear()}-${now.getMonth() +
                1}-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`

            const rejection = {
                date: rejectionDate,
                valuer: this.$store.state.auth.email,
                onsite_reasons: this.onsite_reasons,
                rejection_comments: this.rejection_comments,
            }

            this.UPDATE_DVM_REJECTIONS(rejection)

            await this.$axios
                .patch(
                    utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                        'request',
                        this.valuation_request_ref,
                    ]),
                    { dvm_rejections: rejection }
                )
                .then(() => {
                    this.$axios
                        .put(
                            utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                                'request',
                                this.valuation_request_ref,
                                'status',
                            ]),
                            null,
                            { params: { action: 'defer-to-onsite' } }
                        )
                        .then(() => {
                            this.onSiteSent = true
                        })
                        .catch((e) => {
                            this.closeModals()
                            this.showError(e, 'onsite_put')
                            console.error(e)
                        })
                })
                .catch((e) => {
                    this.closeModals()
                    this.showError(e, 'onsite_patch')
                    console.error(e)
                })
        },
        async resubmit() {
            let features = { ...this.getFeatures }

            delete features.f_building_type
            delete features.building_id
            delete features.building_ids
            delete features.parcel_ids

            await this.$axios
                .patch(
                    utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                        'request',
                        this.valuation_request_ref,
                    ]),
                    { features }
                )
                .then(() => {
                    this.$axios
                        .put(
                            utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                                'request',
                                this.valuation_request_ref,
                                'status',
                            ]),
                            null,
                            { params: { action: 'submit' } }
                        )
                        .then(() => {
                            this.$router.go()
                        })
                        .catch((e) => {
                            this.closeModals()
                            this.showError(e, 'resubmit_put')
                            console.error(e)
                        })
                })
                .catch((e) => {
                    this.closeModals()
                    this.showError(e, 'resubmit_patch')
                    console.error(e)
                })
        },
        backToDashboard() {
            this.$router.push({ name: 'dashboard' })
        },
        goToReport() {
            this.$router.push({
                name: 'report',
                params: {
                    lang: this.$route.params.lang,
                },
                query: {
                    valuation_request_ref: this.valuation_request_ref,
                    static: 'true',
                },
            })
        },

        load_duplicates() {
            this.shownDuplicate = this.request
            this.shownDuplicate.created_at = new Date(this.request.created_at)
            this.shownDuplicate.address = this.getAddress.full_address
            this.isDuplicatesLoading = true
            this.currentDuplicatesPage = 1
            let url = utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, 'requests')
            let dupl = {}
            const params = {}
            params.valuation_request_refs = ''
            this.request.other_data.similar_requests.forEach((req, i) => {
                if (i) params.valuation_request_refs += ','
                const ref = req.split(' ')[0]
                const reason = req.split(': ')[1]
                params.valuation_request_refs += ref
                dupl[ref] = reason
            })
            params.load_all = 'true'
            this.$axios.get(url, { params }).then((result) => {
                this.duplicatesData = result.data.map((row, index) => {
                    return {
                        ...row,
                        created_at: new Date(row.created_at),
                        index: index,
                        address: this.display_address(row),
                        deployment: this.isDispatchDashboard
                            ? row.valuation_request_ref.split('_')[0]
                            : null,
                        display_request_ref: this.isDispatchDashboard
                            ? row.valuation_request_ref.split('_')[1]
                            : null,
                        display_building_type: row.building_type
                            ? this.$t(`value.${row.building_type}`)
                            : '',
                        displayStatus: this.$t(`status.${row.status}`),
                        displayOwner: row.owner ? row.owner.email : '',
                        displayValuer: !this.isValuer
                            ? 'N/A'
                            : row.valuer.assigned
                            ? row.valuer.username
                            : 'N/A',
                        reason: dupl[row.valuation_request_ref],
                    }
                })
                this.isDuplicatesLoading = false
            })
        },
        close_duplicates() {
            this.shownDuplicate = null
            this.isDuplicatesLoading = false
            this.duplicatesData = []
            this.currentDuplicatesPage = 1
        },
    },
}
</script>
<style lang="scss" scoped>
@for $i from 1 through 10 {
    .gap-#{$i} {
        gap: #{$i}em;
    }
}

#valuation-tool {
    min-height: 100vh;
}

@media screen and (min-width: 812px) {
    .sticky-column {
        position: sticky;
        top: 0.2rem;
        height: fit-content;
        max-height: 100vh;
        overflow: scroll;
    }
}

.links {
    display: flex;
    flex-direction: column;
}
.map-container {
    height: 40vh;
    width: 100%;
}
.incoherent-features-grid {
    display: grid;
    grid-template-columns: auto max-content;
    align-items: end;
    column-gap: 0.5rem;
    row-gap: 1rem;
}
.modal-card-foot {
    display: block;
}
.incoherent-no-field {
    padding: 7px 11px;
    display: block;
    color: #7a7a7a;
    border-radius: 4px;
    background: #f5f5f5;
}
</style>
